$separator-color-light: #313131;
$separator-color: #424242;
$background-color: #1d1a1d;
$foreground-color: #242224;
$input-background: #232223;

$dark-btn-background: #8d8d8d;
$light-btn-background: #e4e4e4;
$button-text-color: #d0d0d0;

$theme-color-1: #7e4877;
$theme-color-2: #3c4b9a;
$theme-color-3: #af67a4;
$theme-color-4: #743c6e;
$theme-color-5: #4b5480;
$theme-color-6: #795d75;

$primary-color: #8f8f8f;
$secondary-color: #707070;
$muted-color: #696969;

$gradient-color-1 : #8a5381;
$gradient-color-2 : #7e4877;
$gradient-color-3 : #804a77;

$lp-bg-color-1 : #240429;
$lp-bg-color-2 : #29072b;
$lp-bg-color-3 : #420e40;
$lp-bg-color-4 : #52124c;

$shadow-offsets-top : 1 3 10 14 19;
$shadow-blurs-top: 2 6 10 14 19;
$shadow-opacities-top: 0.1 0.3 0.6 0.7 0.8;

$shadow-offsets-bottom : 1 3 6 10 15;
$shadow-blurs-bottom: 3 6 6 5 6;
$shadow-opacities-bottom: 0.1 0.3 0.6 0.7 0.8;

$logo-path: "../../logos/white.svg";
$logo-path-mobile: "../../logos/mobile.svg";

$lp-logo-path-pinned: "../../logos/white-full.svg";
$lp-logo-path: "../../logos/white-full.svg";

@import "../_mixins.scss";
@import "../_vien.style.scss";
